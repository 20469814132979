//import APIService_2 from "@/services/APIService_2";
import APIServiceWP from "@/services/APIServiceWP";
import APIService from "@/services/APIService";
import gruppemitgliedService from "@/services/gruppemitglied.service";
import APIServiceSync from "@/services/APIServiceSync";

export default {
    // eslint-disable-next-line no-unused-vars
    async getModel(id = "", schema){
        console.log("get model sammelgruppe "+id);
        if(id !== ""){
            let model = await APIService.getModel('Collectiongroups', id);
            console.log(model);
            model.schema = schema;
            return model;
        }else{
            let model = await APIService.getAllModel('Collectiongroups');
            for(let i=0; i < model.length; i++){
                model[i].schema = schema;
            }
            return model;
        }
    },
    async saveModel(model, id, syncAll = true){
        const _model = { ...model }
        delete _model.schema;
        delete _model.created;
        delete _model.creator;
        delete _model.id;
        delete _model.schemaType;

        let res;

        if(!id || id === "") {
            res = await APIService.createSammelgruppe(_model);
        }else{
            //update sammelgruppe
            res = await APIService.updateSammelgruppe(_model, id)
        }
        //Sync to Lerngruppe
        APIServiceSync.syncBG2LD();

        if(res.status === 200 || res.status === 201){
            console.log("success on save "+res.data.id);
            let reswp = await this.syncModel(model, res.data.id, syncAll);
            console.log(reswp);
        }
        return res;
    },

    async syncSingleUser(model, InternetUser, add){
        let sites = JSON.parse(process.env.VUE_APP_SYNC_SITES);
        for (let i = 0; i < sites.length; i++) {
            try {
                APIServiceWP.setAPIClient(sites[i]);
                console.log("sync " + sites[i]);

                if (add) {
                    await APIServiceWP.addUserToGroup(model.id, InternetUser);
                } else {
                    await APIServiceWP.removeUserFromGroup(model.id, InternetUser);
                }
            } catch (error) {
                // Hier kannst du auf die Ausnahme reagieren, z.B. eine Fehlermeldung ausgeben.
                console.error("Fehler aufgetreten:", error);
            }
        }
        return true;
    },

    async syncModel(model, id, syncAll = true){
        const _model = { ...model }
        _model.syncall = syncAll ? "1" : "0";
        console.log("sync model "+id+" all: "+syncAll);
        console.log("model syncall: "+_model.syncall);
        //sync with all sites
        let sites = JSON.parse(process.env.VUE_APP_SYNC_SITES);
        console.log(sites);
        if(id != "" && syncAll){
            delete _model.schema;
            _model.members = await this.getAllUsers(_model);
        }
        console.log("syncModel!");
        console.log(_model.members);
        const originalMembers = [..._model.members];
        for(let i=0; i < sites.length; i++) {
            console.log("sync site: "+sites[i]);
            _model.members = [...originalMembers];
            if (sites[i] === "shop") {
                console.log("filter members for shop");
                _model.members = _model.members.filter(member => member.KundeTyp === "F");
            }

            APIServiceWP.setAPIClient(sites[i]);
            console.log(_model.members);

            if (id != "") {
                await APIServiceWP.putGroup(_model, id);
            } else {
                model.id = id;
                await APIServiceWP.postGroup(_model, id);
            }
        }
        return true;
    },

    async deleteSammelgruppe(item){
        let sites = JSON.parse(process.env.VUE_APP_SYNC_SITES);
        console.log(sites);
        await APIService.deleteSammelgruppe(item.id);
        for(let i=0; i < sites.length; i++) {
            APIServiceWP.setAPIClient(sites[i]);
            await APIServiceWP.deleteGroup(item);
            console.log("next");
        }
        return true;
    },

    async getAllUsersId(id){
        let model = await this.getModel(id);
        return await this.getAllUsers(model);
    },
    async getAllUsers(model){
        let users = [];
        if(model.groups == null) return users;
        for(let i=0; i < model.groups.length; i++){
            let usersTmp = await gruppemitgliedService.getMitgliederForGruppe(model.groups[i]);
            for(let j=0; j<usersTmp.length; j++){
                let found = false;
                console.log("getAllUsers");
                console.log(usersTmp[j]);
                for(let k=0; k < users.length; k++){
                    if(users[k].UKeyKunde == usersTmp[j].UKeyKunde){
                        found = true;
                    }
                }
                if(!found){
                    console.log("add");
                    users.push(usersTmp[j]);
                }
            }
        }
        return users;
    },


    async getSammelgruppenForGruppe(gruppe_id, filterLerngruppe = false){
        let sammelgruppen = await APIService.getAllSammelGruppen();
        let foundSGs = sammelgruppen.filter(sg => sg.groups.includes(gruppe_id));
        if (filterLerngruppe) {
            let licences = await APIService.getLicences();

            if(licences && licences.length) {
                foundSGs = foundSGs.map(sg => {
                    let licenceExists = licences.some(licence => licence.orderNumber === sg.id);
                    return {...sg, licenceExists};
                });
            }
        }
        return foundSGs;
    },

    async addGroup(model, newGroup){
        console.log("add "+newGroup.Bezeichnung+" to "+model.name);

        if(!model.groups.includes(newGroup.id)){
            model.groups.push(newGroup.id);
            await this.saveModel(model, model.id);
        }
    },

    async removeGroup(model, group){
        if(model.groups.includes(group.id)){
            model.groups = model.groups.filter(e => e !== group.id);
            await this.saveModel(model, model.id);
        }
    },

    async getContext(model){
        return model;
    }
}
